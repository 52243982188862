<template>
  <div>

    <div v-if="$store.state.dashboard.is_loaded2">

      <vs-row>

        <!-- interval OSD -->
        <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
          <div class="title_range">
            Showing data for the interval between <b>{{ formatReadable(date_from, 'll') }}</b> and
            <b>{{ formatReadable(date_to, 'll') }}</b>
          </div>
        </vs-col>

        <!-- datepicker : icon-->
        <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="6">
          <vs-button @click="is_datepik_visible=true" color="success" type="flat">
            <vs-icon icon="event"></vs-icon>
          </vs-button>
        </vs-col>
      </vs-row>

      <!-- datepicker -->
      <Fx-Modal v-show="is_datepik_visible" @close="closeDatePik">
        <template v-slot:header></template>
        <template v-slot:body>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <DateRangePicker :panel="'week'"
                             :panels="['week', 'month', 'quarter', 'year']"
                             @update="datepikUpdate" @reset="datepikReset" :future="false">
            </DateRangePicker>
          </vs-col>
        </template>
        <template v-slot:footer>
        </template>
      </Fx-Modal>

      <!--This is for tab headings-->
      <vs-row class="tab_heading_container">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="tab_heading_item_parent"
                :class="{tab_heading_active: visible_tab==='employee'}">
          <span @click="tabclick('employee')" class="tab_heading_item">Data Metrics</span>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="tab_heading_item_parent"
                :class="{tab_heading_active: visible_tab==='event'}">
          <span @click="tabclick('event')" class="tab_heading_item">Well-being Experiences</span>
        </vs-col>
      </vs-row>


      <div v-show="visible_tab==='employee'" class="emp-sec">

        <!-- cards -->
        <vs-row>
          <vs-col vs-w="12">
 
            <EmployeeCards  :data="$store.state.dashboard.dashboard2.engagement.data_metrics.cards"></EmployeeCards>

          </vs-col>
        </vs-row>

        <br>
        <br>

        <!-- Employee Trends -->
        <h5 class="chart_sec_heading">DAU Trends by Month</h5>

        <EmployeeGraph1 :series="$store.state.dashboard.dashboard2.engagement.data_metrics.dau" :type="1" width="100%"></EmployeeGraph1>

        <br>
        <br>

        <!-- Employee Trends -->
        <h5 class="chart_sec_heading">MAU Trends by Month</h5>
        <EmployeeGraph2 :series="$store.state.dashboard.dashboard2.engagement.data_metrics" width="100%"></EmployeeGraph2>
      </div>


      <!-- [SECOND = TAB] Well ness events REPORT  -->
      <div v-show="visible_tab==='event'" class="evt-sec">

        <OtherCards :data="$store.state.dashboard.dashboard2.engagement.well_being_experiences.cards"></OtherCards>

        <br>


        <!-- Well-being Engagement (Events) -->
        <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Well-being Engagement (Events)</h5>
            <EventGraph2 :list="$store.state.dashboard.dashboard2.engagement.well_being_experiences.wellbeing_events"
            ></EventGraph2>

            <!-- this is group by category of events -->
          </vs-col>
        </vs-row>

        <br> <br>

        <!-- Well-being Engagement (Cafe) -->

        <vs-row>
          <vs-col vs-w="12">
            <h5 class="chart_sec_heading">Well-being Engagement (Cafe)</h5>
            <EventGraph2 :list="$store.state.dashboard.dashboard2.engagement.well_being_experiences.wellbeing_cafes"/>
          </vs-col>
        </vs-row>

 

 

      </div>

    </div>

  </div>
</template>

<script>
import moment from 'moment-timezone';
import Vue from 'vue'
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";


// Import the component
import VueSkeletonLoader from 'skeleton-loader-vue';


//UI
import FxModal from './analytic-dash/blocks/Modal'

//Employee
import EmployeeCards from './analytic-dash/employee/Employee-cards.vue'
import EmployeeGraph1 from './analytic-dash/employee/graphs/1.vue'
import EmployeeGraph2 from './analytic-dash/employee/graphs/2.vue'
import EmployeeGraph3 from './analytic-dash/employee/graphs/3.vue'
import EmployeeGraph4 from './analytic-dash/employee/graphs/4.vue'
import EmployeeGraph5 from './analytic-dash/employee/graphs/5.vue'


//Content
import ContentCards from './analytic-dash/content/Content-cards.vue'
import ContentGraph1 from './analytic-dash/content/graphs/1.vue'
import ContentGraph2 from './analytic-dash/content/graphs/2.vue'
import ContentGraph3 from './analytic-dash/content/graphs/3.vue'
import ContentGraph4 from './analytic-dash/content/graphs/4.vue'
import ContentGraph5 from './analytic-dash/content/graphs/5.vue'
import ContentGraph6 from './analytic-dash/content/graphs/6.vue'
import ContentGraph7 from './analytic-dash/content/graphs/7.vue'


//Event
import EventCards from './analytic-dash/event/Event-cards.vue'
import EventGraph1 from './analytic-dash/event/graphs/1.vue'
import EventGraph2 from './analytic-dash/event/graphs/2.vue'


//Other
import OtherCards from './analytic-dash/other/Other-cards.vue'
import CafeGraph1 from './analytic-dash/other/graphs/1.vue'
import CafeGraph2 from './analytic-dash/other/graphs/2.vue'


import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
// cust: https://vuejsexamples.com/vue-js-date-range-picker-with-multiples-ranges-and-presets/

Vue.use(DateRangePicker)


// ** dummy DATA **
import EmpGraph1DATA from './analytic-dash/data/emp_graph1_data.json'
import EmpGraph2DATA from './analytic-dash/data/emp_graph2_data.json'
import EmpGraph3DATA from './analytic-dash/data/emp_graph3_data.json'
import EmpGraph4DATA from './analytic-dash/data/emp_graph4_data.json'

// ** dummy DATA **
import ContentGraph1DATA from './analytic-dash/data/content_graph1_data.json'
import ContentGraph2DATA from './analytic-dash/data/content_graph2_data.json'
import ContentGraph3DATA from './analytic-dash/data/content_graph3_data.json'
import ContentGraph4DATA from './analytic-dash/data/content_graph4_data.json'
import ContentGraph5DATA from './analytic-dash/data/content_graph5_data.json'
import ContentGraph6DATA from './analytic-dash/data/content_graph6_data.json'
import ContentGraph7DATA from './analytic-dash/data/content_graph7_data.json'

// ** dummy DATA **
// import EventGraph1DATA from './analytic-dash/data/event_graph1_data.json'

export default {
  data() {
    return {

      dau: [],
      mau:[],
      emp_count: 0,
      sticky:0,

      is_loading: true,
      visible_tab: 'employee',
      is_datepik_visible: false,

      date_from: '',
      date_to: '',
      date_window: 'quarter',


      //=============================
      chart_title: 'Your title goes here',
      chart_source: 'Your source goes here',
      chart_data: [
        //...
 
        {hours: 1648, production: 9613, year: '2007'},
        {hours: 2479, production: 6315, year: '2008'},
        {hours: 3200, production: 2541, year: '2009'},
 
      ],
      chart_config: {
        key: 'year',
        currentKey: '2004',
        values: ['hours'],
        axis: {
          yTicks: 3
        },
        color: {
          default: '#222f3e',
          current: '#41B882'
        }
      },

      margin: {
        top: 10,
        right: 100,
        bottom: 20,
        left: 100
      },

      transition: {
        duration: 350,
        ease: "easeLinear",
      },
      tooltip: { label: false },
    };
  },
  components: {
    VueSkeletonLoader,


    StatisticsCardLine,

    //UI
    FxModal,

    //Employee
    EmployeeCards,
    EmployeeGraph1,
    EmployeeGraph2,
    EmployeeGraph3,
    EmployeeGraph4,
    EmployeeGraph5,

    //Content
    ContentCards,
    ContentGraph1,
    ContentGraph2,
    ContentGraph3,
    ContentGraph4,
    ContentGraph5,
    ContentGraph5,
    ContentGraph6,
    ContentGraph7,

    //Event
    EventCards,
    EventGraph1,
    EventGraph2,

    //Other
    OtherCards,
    CafeGraph1,
    CafeGraph2
  },
  computed: {

  },
  mounted() {

  },
  created() {
    this.date_from = moment().subtract(2, 'months').utc().format('YYYY-MM-DD HH:mm:ss')

    this.date_to = moment().utc().format('YYYY-MM-DD HH:mm:ss')

    this.date_window = 'quarter'

    if(!this.$store.state.dashboard.is_loaded2){
      this.load()
    }
  },
  methods: {
    datepikReset(){

      this.date_from = moment().subtract(2, 'months').utc().format('YYYY-MM-DD HH:mm:ss')

      this.date_to = moment().utc().format('YYYY-MM-DD HH:mm:ss')

      this.date_window = 'quarter'

      this.load()

      this.is_datepik_visible = false

    },
    formatReadable(date, fmt) {
      if (moment(date).unix() > moment().unix()) {
        return 'Today'
      }
      return moment(date).format(fmt);
    },
    load() {

      this.is_loading = true
      this.$vs.loading();

      this.$store.dispatch('dashboard/initializeDashboard2', {

            org_id: this.$store.state.AppActiveUser.org_id,
            date_from: this.date_from,
            date_to: this.date_to,
            date_window: this.date_window

      }).then((data)=>{

        // this.emp_count = data.emp_count
        // this.sticky = data.sticky
        // this.dau = data.dau
        // this.mau = data.mau

        this.is_loading = false
        this.$vs.loading.close();
      })

    },

    //handles the task of hiding and showing correct tab on click on tab heading
    tabclick(t) {
      this.visible_tab = t
    },

    datepikUpdate(arg) {
      this.is_datepik_visible = false

      this.date_from = moment(arg.from);//.utc().format('YYYY-MM-DD HH:mm:ss')

      if(moment(arg.to).unix()>moment().unix()){
        this.date_to = moment();//.utc().format('YYYY-MM-DD HH:mm:ss')
      }else{
        this.date_to = moment(arg.to);//.utc().format('YYYY-MM-DD HH:mm:ss')
      }

      this.date_window = arg.panel

      this.load()
    },

    closeDatePik() {
      this.is_datepik_visible = false
    }
  },

};
</script>
<style>
.tab_heading_item {
  font-weight: bold;
  width: 100%;
  text-align: center;

}

.tab_heading_item_parent {
  background: white;
  cursor: pointer;
}

.tab_heading_active {

  background: #1c54a2;
  padding: 10px;
  color: white;
  display: inline-block;
  border-radius: 10px;
}

.tab_heading_container {
  margin-bottom: 20px;
  padding: 5px;
  background: #ffffff;
}

.title_range {
  color: silver;
}


.chart_sec_heading{
  padding: 5px 5px 5px 10px; background : gainsboro; border-radius: 5px;
}
</style>
