<template lang="html">
  <div>

    <vs-table :data="list" style="border: 4px solid #f0f0f0;">
      <template slot="header">

      </template>
      <template slot="thead">
        <vs-th>
          Category
        </vs-th>
       
        <vs-th>
          Total Events
        </vs-th>

        <vs-th>
          Total Participants
        </vs-th>

        <!--        <vs-th>
                  Average Participants
                </vs-th>-->

        <vs-th>
          Total length (Hrs.)
        </vs-th>
        <!--        <vs-th>
                  Average length (Hrs.)
                </vs-th>-->

        <vs-th>
          Rating
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

          <vs-td :data="data[indextr].name">
            {{data[indextr].name}}
          </vs-td>

          <vs-td :data="data[indextr].count">
            {{data[indextr].count}}
          </vs-td>

          <vs-td :data="data[indextr].total_participants">
            {{data[indextr].total_participants}}
          </vs-td>
          <!--          <vs-td :data="data[indextr].avg_participants">
                      {{data[indextr].avg_participants}}
                    </vs-td>-->

          <vs-td :data="data[indextr].total_event_length">
            {{data[indextr].total_event_length}}
          </vs-td>
          <!--          <vs-td :data="data[indextr].avg_event_length">
                      {{data[indextr].avg_event_length}}
                    </vs-td>-->

          <vs-td :data="data[indextr].rating">

            <!--            <vs-progress :percent="getPct(data[indextr].rating, 5)" color="success" v-if="data[indextr].rating">-->
            <!--              -->
            <!--            </vs-progress>-->
            <span v-if="data[indextr].rating">{{ data[indextr].rating }}/5</span>
            <span v-else>-</span>
 
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from 'moment-timezone' 
import Vue from 'vue'

Vue.prototype.moment = moment

export default {
  name: 'EventGraph2',
  props:['list'],
  data(){
    return {

    }
  },
  methods:{
    getPct(n, t){
      let pct = n/t * 100
      return pct.toFixed(0)
    }
  }
}
</script>