<template>
  <div>

    <apexchart type="bar" height="350"
               :options="lseries.chartOptions"
               :series="lseries.series"
           >
    </apexchart>
  </div>

</template>

<script>

import VueApexCharts from "vue-apexcharts";

import moment from 'moment-timezone'
import Vue from 'vue'

export default {
  name: 'EmployeeGraph2',
  props: ['series'],
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    // series(val) {
    //
    //   this.config.series = [{
    //     name: 'MAU',
    //     type: 'column',
    //     data: val.mau.arrMauY
    //   }/*, {
    //     name: 'DAU',
    //     type: 'line',
    //     data: val.dau.arrDauY
    //   }*/]
    //
    //   this.config.chartOptions.labels = val.mau.arrMauX
    //
    //   this.renderKey = Math.random()
    // },
  },
  created() {
    // let val = this.series

    // console.log('[[[[[[[]]]]]]]]]', val)
    // this.config.series[0].data = val.mau.arrMauY
    // // this.config.chartOptions.xaxis.categories = val.arrMauX
    // this.config.series[1].data = val.mau.arrDauY
    // // this.config.chartOptions.xaxis.categories = val.arrMauX


    // this.config.series = [{
    //   name: 'MAU',
    //   type: 'column',
    //   data: val.mau.arrMauY
    // }/*, {
    //   name: 'DAU',
    //   type: 'line',
    //   data: val.dau.arrDauY
    // }*/]

    // this.config.chartOptions.labels = val.mau.arrDauX

    // this.config.series = [{data: this.series.mau}]

  },
  data() {
    return {
      renderKey: 0,

    }
  },
  computed:{
    lseries(){
      let local = [{data: this.series.mau, name: 'MAU'}]

      let cfg = {
        // Part I
        series: local,
        // Part II
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
          fill: {
            colors: ['#533483']
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + " Users";
            },
            offsetY: 20,
            style: {
              fontSize: '14px',
              colors: ["#fff"]
            }
          },

          xaxis: {
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            // labels: {
            //   show: false,
            //   formatter: function (val) {
            //     return val + " Active users ";
            //   }
            // }

          },
          title: {
            text: '',
            floating: true,

            align: 'center',
            style: {
              color: '#444'
            }
          }
        },
      }

      return cfg
    },
  }
}
</script>