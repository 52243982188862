<template>
  <div>


    <div class="w-full bg-blue-50 p-6">

      <div class="flex justify-around">


        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/4 md:w-1/4 lg:w-1/4">
          <vx-tooltip color="primary" text="Total clicks on Confidant contact" position="bottom">
            <statistics-card-line2 icon="LogInIcon" :statistic="data.confidant_clicks" statisticTitle="Confidant clicks <br> &nbsp;" type="area"></statistics-card-line2>

          </vx-tooltip>
        </div>



        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/4 md:w-1/4 lg:w-1/4">
          <vx-tooltip color="primary" text="Total Confidant Chat based Tickets" position="bottom">
            <statistics-card-line2 icon="MessageCircleIcon" :statistic="data.confidant_tickets_chat" statisticTitle="Confidant Chats <br> &nbsp;" type="area"></statistics-card-line2>
          </vx-tooltip>
        </div>

        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/4 md:w-1/4 lg:w-1/4">
          <vx-tooltip color="primary" text="Total Confidant Call based Tickets" position="bottom">
            <statistics-card-line2 icon="PhoneCallIcon" :statistic="data.confidant_tickets_call" statisticTitle="Confidant Call <br> &nbsp;" type="area"></statistics-card-line2>
          </vx-tooltip>
        </div>


        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/4 md:w-1/4 lg:w-1/4">
          <vx-tooltip color="primary" text="Total Confidant Offline Tickets" position="bottom">
            <statistics-card-line2 icon="ClipboardIcon" :statistic="data.confidant_external_tickets" statisticTitle="Confidant Offline <br> Reachout" type="area"></statistics-card-line2>
          </vx-tooltip>
        </div>

      </div>

    </div>




    <vs-row>




<!--       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Hits on Offers" position="top">
          <statistics-card-line icon="MessageCircleIcon" :statistic="data.offers_view_more_count" statisticTitle="Offer Hits" type="area"></statistics-card-line>
        </vx-tooltip>
      </div> -->


    </vs-row>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import StatisticsCardLine2 from "@/components/statistics-cards/StatisticsCardLine2.vue";

export default {
  name: 'OtherCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine, StatisticsCardLine2
  },
}
</script>