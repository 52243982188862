<template>
  <div>


    <div class="w-full bg-blue-50 p-6">

      <div class="flex justify-between">

        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3">
          <vx-tooltip color="primary" text="Employees who downloaded and registered themselves on iSabel app" position="bottom">
            <statistics-card-line2 icon="UsersIcon" :statistic="data.emp_count" statisticTitle="Registered Employees" type="area"
            plus=""
            ></statistics-card-line2>
          </vx-tooltip>
        </div>

        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3">
            <vx-tooltip color="primary" text="DAU%" position="bottom">
            <statistics-card-line2 icon="SmartphoneIcon" :statistic="data.current_month_max_dau"
                                  statisticTitle="Max DAU" type="area"></statistics-card-line2>
          </vx-tooltip>
        </div>

        <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-2 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3">
          <vx-tooltip color="primary" text="Stikyness of the app" position="bottom">
            <statistics-card-line2 icon="SmartphoneIcon" :statistic="!isNaN(data.sticky)?parseFloat(data.sticky).toFixed(1):'(Not enough data)'" statisticTitle="DAU/MAU %" type="area"></statistics-card-line2>
          </vx-tooltip>
        </div>

      </div>

    </div>

 

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import StatisticsCardLine2 from "@/components/statistics-cards/StatisticsCardLine2.vue";

export default {
  name: 'EmployeeCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine, StatisticsCardLine2
  },
}
</script>